<template>
  <v-card elevation="0" max-width="1010" rounded="xl" class="mx-auto pa-sm-8 pa-4 registration-confirm width100">
    <v-btn class="btn-back px-2" text @click="back">
      <v-icon small color="gray" class="pt-1">mdi-chevron-left mdi-24px</v-icon>
      <span class="gray--text text-body-1">{{ $t('btn.back') }}</span>
    </v-btn>
    <v-img height="105" contain class="mx-auto mb-12" src="@/assets/icon/logo.svg" alt="logo"></v-img>
    <div class="f30 arabic-black text-center mb-2 black--text">{{ $t('loginConfirm.title') }}</div>
    <div class="gray--text text-center mb-8">{{ $t('loginConfirm.description') }}</div>
    <div style="max-width: 380px" class="mx-auto mb-9">
      <v-form @submit.prevent="otp" ref="form" lazy-validation>
        <v-text-field
          class="input-center"
          v-model="code"
          :error-messages="codeErrors"
          :placeholder="$t('input.codePla')"
          outlined
          required
          color="secondary"
        ></v-text-field>
        <div class="d-flex align-center justify-center mb-12">
          <span class="gray--text text-body-2 pe-2">{{ $t('loginConfirm.notCode') }}</span>
          <span class="secondary--text text-body-2 font-weight-bold btn-underline link" @click="resend">
            {{ $t('btn.resendCode') }}
          </span>
        </div>
        <v-btn height="64" depressed type="submit" block large class="primary radius10">
          <span class="text-body-1 font-weight-bold">{{ $t('btn.continue') }}</span>
        </v-btn>
      </v-form>
    </div>
  </v-card>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      code: '',
      error: [],
    };
  },
  methods: {
    notifi(btn) {
      if (btn == 'resend') {
        this.$notify({
          title: '',
          message: this.$t('alert.otpResend'),
          type: 'error',
          icon: 'mdi-check',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == 500) {
        this.$notify({
          title: this.$t('alert.error500Title'),
          message: this.$t('alert.error500'),
          type: 'error',
          icon: 'mdi-alert',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      }
    },
    back() {
      this.$router.push('/sign-up');
    },
    async otp() {
      this.error = [];
      const data = new Object();
      data.sid = sessionStorage.getItem('sid');
      data.otp = this.code;
      await this.$store
        .dispatch('signUpOtp', data)
        .then((res) => {
          sessionStorage.setItem('accessToken', res.data.access_token);
          setTimeout(() => {
            this.$store.dispatch("getProfile").then(() => {
              setTimeout(() => {
                if (this.profile.is_profile_filled) {
                  this.$router.push("/");
                } else {
                  this.$router.push("/profile-filling");
                }
              }, 100);
            })
          }, 300);
          // this.$router.push('/account-confirm');
        })
        .catch((e) => {
          this.error = e.response.data.error;
          if (e.response.status >= 500) {
            this.notifi(500);
          }
        });
    },
    async resend() {
      this.error = [];
      await this.$store
        .dispatch('otpResend', { sid: sessionStorage.getItem('sid') })
        .then((res) => {
          sessionStorage.removeItem('sid');
          setTimeout(() => {
            sessionStorage.setItem('sid', res.data.sid);
          }, 200);
          this.notifi('resend');
        })
        .catch((e) => {
          this.error = e.response.data.error;
        });
    },
  },
  computed: {
    profile() {
      return this.$store.getters.profile;
    },
    codeErrors() {
      const errors = [];
      this.error.find((item) => item == 'sid__required') && errors.push(this.$t('inputError.sidRequired'));
      this.error.find((item) => item == 'sid__expired') && errors.push(this.$t('inputError.otpExpired'));
      this.error.find((item) => item == 'otp__required') && errors.push(this.$t('inputError.sidRequired'));
      this.error.find((item) => item == 'otp__expired') && errors.push(this.$t('inputError.otpExpired'));
      this.error.find((item) => item == 'otp__not_found') && errors.push(this.$t('inputError.otpNotFound'));
      this.error.find((item) => item == 'bad_credentials') && errors.push(this.$t('inputError.otpNotFound'));
      this.error.find((item) => item == 'action__limit_exceeded') && errors.push(this.$t('inputError.limitExceeded'));
      return errors;
    },
  },
  destroyed() {
    sessionStorage.removeItem('sid');
  },
};
</script>

<style lang="scss">
.registration-confirm {
  position: relative;
  .btn-back {
    z-index: 1;
    position: absolute;
    top: 50px;
    left: 50px;
  }
}
</style>
